import React from 'react';
import './App.css';

function App() {
  var htmlcode = '<body><video controls allowfullscreen loop playsinline poster="http://museumtourguides1.appspot.com/nattyhistory/big/482v.jpg" width="100%" height="100%"><source src="https://cdn.vidyard.com/videos/vMKYG2l0qkpURvrUkr9lQA/sd.mp4?FwrWKrRR75LW4vkWVaAIYiy2nnVDzm3g22YxuNkBqOf8XH6OYv-oIdNJ6aKv8H1Gk3Kc2SeAbQonZ5JqDCf4LW-t6mYoMuZIBZhA2u_7skFrnKwoqpzUi-ejtLUEfcolpMakiW7pVw" type="video/mp4"></video></body>';
  return (
    <div className="App">
      <header className="App-header">

         <div className="content" dangerouslySetInnerHTML={{__html: htmlcode}}></div>
      </header>
    </div>
  );
}

export default App;
